.mat-mdc-card-title{
	font-size:rem-calc(14);
	font-weight:500;
	margin-bottom:rem-calc(14);
}
/*

TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
mat-card.mat-mdc-card{
	padding:rem-calc(20);
	box-shadow: 0 0 2px 0 rgba(197, 207, 218, 1);
	border-color:transparent;
}

/*

TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
mat-card-footer.mat-mdc-card-footer{
	margin: 0 -20px -20px -20px;
}

/*

*/
