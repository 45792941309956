.ins-full-screen-edit {
	transition:all 0.2s linear 0s;
	#header {
		@include body-background;
		padding-top: 40px;
		padding-bottom: 24px;

		button {
			+ button {
				margin-left: 10px;
			}
		}
	}

	.ins-fixed-header--scrolled{
		box-shadow:0 -2px 4px rgba($dark, 0.5);
	}
}


