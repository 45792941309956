$primary: $blue;
$primary-gradient: $blue-gradient;
$secondary: $lightblue;


$body-bg: $lightblue;
$body-color:$dark;

$font-family-base: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

$theme-colors: (
	"primary":$primary,
	"secondary":$secondary,
	"light":$light,
	"dark":$dark,
	"alert": $red,
	"orange" : $orange,
	"lightgrey": $lightgrey,
	"lightblue": $blue-100,
	"purple": $purple
);

$h1-font-size:              rem-calc(24);
$h2-font-size:              rem-calc(18);
$h3-font-size:              rem-calc(16);
$h4-font-size:              rem-calc(14);
$h5-font-size:              rem-calc(12);
$h6-font-size:   			rem-calc(10);

$text-muted: $grey;


