@use "sass:math";


@mixin primary-gradient() {
	background: linear-gradient(to right, $blue, $blue-gradient);
}

@mixin body-background() {
	background:linear-gradient(to right, #dae5f0, #f1f4f7);
}

@function rem-calc($size) {
	@return #{math.div($size, 16)}rem;
}

@function em-calc($line-height, $font-size){
	@return #{math.div($line-height,$font-size)}em;
}

@mixin responsiveborder{


	border-top:3px solid black;
	&:before{
		position:fixed;
		top:0;
		left:0;
		content:"XS";
		background:black;
		color:white;
		z-index:100000;
	}
	&.admin-bar{
		&:before{
			top:35px;
			@include media-breakpoint-down(sm){
				top:48px;
			}
		}

	}
	@include media-breakpoint-only(sm){
		border-top-color:$blue;
		&:before{
			content:"SM";
			background:$blue;
		}
	}
	@include media-breakpoint-only(md){
		border-top-color:$orange;
		&:before{
			background:$orange;
			content:"MD";
		}
	}
	@include media-breakpoint-only(lg){
		border-top-color:$green;
		&:before{
			background:$green;
			content:"LG";
		}
	}
	@include media-breakpoint-only(xl){
		border-top-color:$yellow;
		&:before{
			background:$yellow;
			content:"XL";
		}
	}
}
