.ins-disabled-container {
	.mat-mdc-card {
		filter: blur(3px);
		opacity: .3;
	}

	&-non-blur {
		.mat-mdc-card {
			filter: none;
			opacity: .5;
		}
	}
}

.ins-disabled-card {
	filter: blur(3px);
	opacity: .3;
}
