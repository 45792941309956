.mat-mdc-form-field{
	margin:0;
	width: 100%;
	&:last-child{
		/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
		.mat-form-field-wrapper{
			margin:0;
			padding:0;
		}
	}
}

.ins-form-field--small{
	//height:34px;
	display:inline-flex;
	align-items:center;
	/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
	.mat-form-field-outline > * {
		background: white;
	}
	/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
	.mat-form-field-infix {
		padding-bottom: .8em;
		border-top: 0;
	}
	/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
	.mat-form-field-wrapper {
		margin: 0 !important;
		padding: 0;
	}
	/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
	.mat-select-arrow-wrapper {
		transform: none !important;
	}
}

.mat-mdc-input-element.big-input{
	font-size:rem-calc(18);
	font-weight:500;
}

/*fieldset {
	border: 0;
	padding: 0;
	margin: 0;
}*/
/*
.mat-form-field-wrapper {
	margin-top: 0;
}*/
/*
.ins-form-field--small {
	height:34px;
	.mat-form-field-infix {
		border-top: 0;
		padding-bottom: .8em;
	}

	.mat-form-field-wrapper {
		margin: 0 !important;
		padding: 0;
	}

	.mat-form-field-outline > * {
		background: white;
	}

	.mat-select-arrow-wrapper {
		transform: none !important;
	}
}

.ins-form-field-large-text {
	.mat-input-element {
		font-size: 18px;
		font-weight: 500;
		margin-bottom: -1px;
		margin-top: -2px;
	}
}

.ins-dual-form-field {
	display: flex;
	justify-content: space-between;

	.mat-form-field {
		width: 48%;
	}
}
*/
