.mat-drawer-container {
	background:transparent;
}
.mat-mdc-snack-bar-container{
	&.success{
		background:$green;
	}
	&.error{
		background:$red;
	}
}


