
.mat-mdc-progress-spinner {
	$spinner-size-small: 24px;

	width: 56px !important;
	height: 56px !important;

	svg {
		width: 56px !important;
		height: 56px !important;
	}

	&.ins-spinner-small {
		width: $spinner-size-small !important;
		height: $spinner-size-small !important;

		svg {
			width: $spinner-size-small !important;
			height: $spinner-size-small !important;
		}
	}
}

.ins-main-content-spinner {
	margin: 0 auto;
	margin-top: 128px;
	width: 56px !important;
	height: 56px !important;
}
.ins-table-spinner {
	display: block;
	background: white;
	width: 100%;
	padding: 40px 0;

	.mat-mdc-progress-spinner {
		margin: 0 auto;
		width: 32px !important;
		height: 32px !important;

		svg {
			width: 32px !important;
			height: 32px !important;
		}
	}
}
