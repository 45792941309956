html, body {
	height: 100%;
	background:linear-gradient(to right, #dae5f0, #f1f4f7);

}
ins-root {
	width: 100%;
	height: 100%;
	display: block;
}

ins-internal {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.ins-main-toolbar{
	background:linear-gradient(to right, $primary, $blue-gradient);
	padding:rem-calc(12);
	padding-top:rem-calc(24);

}
.ins-main-toolbar__logo{
	height: 40px;
	width:100%;
	margin-left:10px;

	@include media-breakpoint-only(xs){
		height:32px;
	}
}


.mat-sidenav-container{
	display:flex;
	height:100%;

}
.mat-sidenav{
	background-color: white;
	width: 232px;
	border-right: 1px solid rgba(0,0,0,0.14);
	nav{
		padding: 16px 0;
		height: 100%;
	}
}
.mat-sidenav__item{
	padding: rem-calc(8) rem-calc(14);
	appearance:none;
	display:flex;
	align-items:center;
	text-decoration:none;
	color:$dark;
	transition: color .2s ease;
	text-transform:uppercase;
	font-size:rem-calc(12);
	line-height:em-calc(18,12);
	border-radius:3px;
	margin-inline:rem-calc(10);
	margin-bottom:5px;
	.mat-icon{
		margin-right:8px;
	}
	&.active{
		color: white;
		@include primary-gradient();
	}
}
.ins-full-screen {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	@include body-background;
	overflow-y: auto;
	//padding-top: $full-screen-fixed-header-height;
}

// Change z-index of drawer to make full screen work
.mat-drawer.mat-drawer-side {
	z-index: 1 !important;
}

/*
.ins-main-toolbar {

	z-index: 1;

	padding: 12px 24px 12px 12px;
	color: white;
	display: flex;
	align-items: center;
	//@include primary-gradient();

	.ins-menu-button {
		min-width: 0;
		margin-left: -8px;
		flex-shrink: 0;
	}

	&__logo-mobile {
		display: none;
		max-height: 32px;

		@media(max-width: 800px) {
			display: block;
		}
	}

	&__logo {
		max-height: 40px;
		@media(max-width: 800px) {
			display: none;
		}
	}

	&__dropdowns-wrapper {
		display: flex;
		margin-left: 80px;

		.ins-main-toolbar__dropdown:first-child {
			margin-right: 12px;
		}

		@media(max-width: 800px) {
			margin-left: 16px;
		}
	}

	&__dropdown {
		color: white;
		width: 120px;
		margin-bottom: 0;

		.mat-select-value {
			color: white;
		}

		.mat-form-field-infix {
			border-top: 0;
			padding-bottom: .8em;
		}

		.mat-form-field-wrapper {
			margin: 0 !important;
			padding: 0;
		}


		.mat-form-field-outline,
		.mat-form-field-outline-thick {
			color: rgba(#fff, .5) !important;
		}

		.mat-select-value-text,
		.mat-select-arrow {
			color: white !important;
		}

		.mat-select-arrow-wrapper {
			transform: none !important;
		}

		@media(max-width: 800px) {
			margin-left: auto;
		}
	}
}
*/
