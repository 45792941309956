h1, .h1{
	text-transform:uppercase;
	font-weight:500;
}

h5, .h5{
	font-weight:500;
	margin:0;
}

.card-title{
	font-weight:500;
	&:only-child{
		margin:0;
	}
}

h2{
	word-break: break-all;
	hyphens:auto;
}

.cursor-link{
	cursor:pointer;
}

.card--inactive{
	:is(span,h2, .mat-icon) {
		opacity:0.5;
	}
}

.headline--card{
	background:$lightgrey;
	border-radius:3px;
	@extend .mt-4;
	@extend .mb-2;
	text-transform:uppercase;
	padding:rem-calc(14) rem-calc(18);
}


.indent-toggler{
	display:block;
	&:not(.mat-mdc-form-field){
		padding-left:rem-calc(44);
	}
	&.mat-mdc-form-field{
		padding-left:rem-calc(40);
	}
}

.col-icon{
	width:30px;
	padding:0;
	text-align:center;
}



.offset-icon-1{
	margin-left:30px;
}
.offset-icon-2{
	margin-left:60px;
}

.badge-lg {
	height:36px;
	padding: 0.4375rem 0.8125rem;
	display: inline-flex;
	vertical-align: middle;
	font-weight: 400;
	line-height: 1.5;
	font-size: 1rem;
}


