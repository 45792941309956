@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: local('Material Icons'),
	local('MaterialIcons-Regular'),
	url(../../assets/fonts/MaterialIcons-Regular.woff2) format('woff2'),
	url(../../assets/fonts/MaterialIcons-Regular.woff) format('woff'),
	url(../../assets/fonts/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
}

/* rubik-300 - latin */
@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 300;
	src: local(''),
	url('../../assets/fonts/rubik-v14-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../../assets/fonts/rubik-v14-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* rubik-regular - latin */
@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	src: local(''),
	url('../../assets/fonts/rubik-v14-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../../assets/fonts/rubik-v14-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* rubik-500 - latin */
@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 500;
	src: local(''),
	url('../../assets/fonts/rubik-v14-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../../assets/fonts/rubik-v14-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* rubik-700 - latin */
@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 700;
	src: local(''),
	url('../../assets/fonts/rubik-v14-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../../assets/fonts/rubik-v14-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
