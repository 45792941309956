.advertisment{
	align-items:center;
	padding:10px;
	border-radius:3px;
	border:1px solid $grey-200;
	background:white;
	position:relative;
	display:inline-block;
	max-width:620px;
	max-height:60px;
	aspect-ratio:31/3;
	span{
		display:inline-flex;
		aspect-ratio:15/1;
		max-width:600px;
		max-height:40px;
		text-align:center;
		padding:5px;
		justify-content:center;
		img{
			height:100%;
			width:auto;
		}
	}
	h6{
		position:absolute;
		font-size:rem-calc(8);
		text-transform:uppercase;
		background:white;
		display:inline-block;
		left:10px;
		top:-4px;
	}

}
