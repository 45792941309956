.cal-month-view {
	.cal-header {
		display: none;
	}
}

.ins-calendar-legend {
	color: $dark;
	display: flex;
	align-items: center;
	padding: 8px 12px;

	.mat-mdc-checkbox {
		margin-right: 32px;
		font-size: 12px;

		&.ins-calendar-legend__games-box {
			/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
			.mat-checkbox-background {
				background: $grey-100 !important;
			}
		}

		&.ins-calendar-legend__posts-box {
			/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
			.mat-checkbox-background {
				background: $blue-150 !important;
			}
		}
		/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
		.mat-checkbox-checkmark-path {
			stroke: $body-color !important;
		}
		/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
		.mat-checkbox-frame {
			border-style: none;
		}
	}

}

ins-event,
mwl-calendar-week-view-event {
	display: block;
}

.ins-calendar-event {
	padding: 4px 6px;
	border-radius: 3px;
	color: $body-color;
	font-size: 12px;
	display: flex;
	cursor: pointer;
	margin-bottom: 4px;
	align-items: center;
	min-width: 0;

	&__time {
		font-weight: $font-weight-normal;
		flex: 0 0 45px;
		margin-right: 8px;
		font-size: 10px;
	}

	&__title {
		font-weight: $font-weight-bold;
		min-width: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.ins-calendar-event-actions {
	width: 24px !important;
	height: 24px !important;
	align-self: flex-end;
	margin-left: auto !important;
	line-height: 24px !important;

	.mat-icon {
		font-size: 18px !important;
	}
}

.ins-calendar-month-events {
	padding: 8px 2px;
}



.ins-post-edit {
	&-header {
		display: flex;
		align-items: center;
		margin-bottom: 16px;
	}

	&-carousel-header {
		display: flex;
		align-items: center;
	}
}


.ins-scheduler {
	&-header-left {
		display: flex;
	}

	&-date-navigation {
		margin-right: 16px;
	}
}







// Calendar

.cal-event {
	.cal-event-title {
		color: $body-color;
	}
}

.cal-month-view .cal-day-cell {
	min-height: 120px;
}

.cal-week-view .cal-header.cal-today,
.cal-month-view .cal-day-cell.cal-today {
	background-color: rgba($primary, .3);
}

.cal-month-view .cal-day-number {
	opacity: 1;
}


// smaller height for month cell top part
.cal-month-view {
	.cal-cell-top {
		min-height: 0;
		padding-top: 4px;
		padding-right: 8px;
	}

	.cal-cell-row .cal-cell {
		transition: background-color .25s ease;
		min-width: 0;
	}

	.cal-days {
		border: 0;
	}

	.cal-day-number {
		margin: 0;
	}
}

// Black color for all day numbers
.cal-month-view .cal-day-cell.cal-weekend .cal-day-number,
.cal-week-view .cal-header.cal-weekend span,
.cal-month-view .cal-day-number {
	color: $body-color;
}

// Don't resize current day number
.cal-month-view .cal-day-cell.cal-today .cal-day-number {
	font-size: 1.2em;
}

// Set background color for current day
.cal-week-view .cal-header.cal-today, .cal-month-view .cal-day-cell.cal-today {
	background-color: #fffcd6;

	color: $yellow;

	.cal-day-number {
		color: $yellow;
	}
}
