/**
	V2.0 JL
 */

.table-list{
	background:transparent;
	.mat-mdc-row{
		margin-block:rem-calc(10);
		background:$light;
		border-radius:3px;
		box-shadow: 0 0 2px 0 rgba(197, 207, 208, 1);
	}
}

.cdk-column--fullwidth{
	flex: 0 1 100%;
}

.cdk-column--fit-content{
	flex: 1 0 fit-content;
	white-space:nowrap;
	padding-left:rem-calc(15);
	padding-right:0 !important;
	min-width:10%;
	&:last-of-type{
		padding-right:15px !important;
	}
}

.mat-mdc-table{
	:is(.mat-mdc-header-cell, .mat-sort-header-button){
		text-transform:uppercase;
		color:$dark;
	}
}
