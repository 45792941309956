/*
.mat-button-toggle-button,
.mat-button,
.mat-raised-button,
.mat-flat-button {
	font-weight: normal;
	text-transform: none;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
	line-height: 36px !important;
}

.mat-button-toggle-group {
	border: 0;
}

.mat-button-toggle-checked {
	@include primary-gradient();
	border-left: 0;

	.mat-button-toggle-label-content {
		color: white;
	}

	.ins-button-toggle-non-persistent & {
		background: white;

		.mat-button-toggle-label-content {
			color: $dark;
		}
	}
}

button {
	//font-family: $font-family;
	cursor: pointer;
}
*/

/*
button.mat-flat-button.ins-button-secondary {
	color: $primary;
	background: transparent;
	box-shadow: inset 0 0 0 1px $primary;

	&[disabled] {
		background-color: transparent !important;
		box-shadow: inset 0 0 0 1px $grey-medium;
		color: $grey-medium;
	}

	.mat-button-focus-overlay {
		display: none;
	}
}

button.mat-primary {
	color: white;
	@include primary-gradient();

	.mat-button-wrapper {
		color: white;
	}

	&[disabled] {
		filter: grayscale(100%);
	}

	&.ins-button-small {
		padding: 0;
		line-height: 22px;
	}

}

.ins-button-square {
	border-radius: 3px;
	color: #393939;
	background: white;
	border: 1px solid $grey-200;
	width: 34px;
	height: 34px;
	font-size: 16px;
	padding: 0;
	cursor: pointer;

	.mat-icon {
		margin: 0 auto;
		display: block;
	}
}

.ins-micro-fab {
	width: 24px;
	height: 24px;
	background: $primary;
	border-radius: 50%;
	border: 0;
	appearance: none;
	color: white;
	display: flex;
	padding: 0;
	align-items: center;
	justify-content: center;

	.mat-icon {
		width: 16px;
		height: 16px;
		font-size: 16px;
		display: block;
	}
}
*/

.btn{
	padding:rem-calc(7) rem-calc(13);
	display:inline-flex;
	vertical-align:middle;
	&[disabled] {
		filter: grayscale(100%);
		color:$white;
	}

}

.btn-icon{
	border-color:$lightblue;
	padding:rem-calc(5);
	mat-icon{
		color:$dark;
		font-size:rem-calc(20);
	}
}

.btn-primary{
	color:$light;
}

.btn-square{
	aspect-ratio:1/1;
	display:inline-flex;
	align-items:center;
	justify-content:center;
}

.btn-square-sm{
	width:32px;
	height:32px;
}

.btn-circle{

	@extend .btn;
	@extend .btn-primary;
	padding:0;

	border-radius:50%;
	width:36px;
	height:36px;
	display:inline-flex;
	align-items:center;
	justify-content:center;
	.mat-icon{
		color:$white;
		font-size:rem-calc(22);
	}
}

.btn-circle-lg{
	@extend .btn;
	@extend .btn-secondary;
	width:60px;
	height:60px;
	border-radius:50%;
	display:inline-flex;
	justify-content:center;
	align-items:center;
}

.btn-circle.btn-circle-sm{
	width:22px;
	height:22px;
	mat-icon{
		font-size:16px;
		line-height:22px;
	}
}

/*
TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
.mat-button-toggle-checked {
	@include primary-gradient();
	color:$white !important;
}

.btn-primary-gradient{
	@extend .btn-primary;
	color:$white;
	background:linear-gradient(to right, $primary, $primary-gradient);
	transition:all 0.2s linear 0s;
	border:0 !important;
	&:hover{
		border-color:$blue;
	}

	&.inactive{
		background:#ecf3fa !important;
		color:$primary !important;
		position: relative;
		z-index:0;
	}
}

/*
TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
/*
TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
.mat-button-toggle-group{
	border:0 !important;
	/*
TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
	.mat-button-toggle{
		border:0 !important;
	}
}
/*
TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
/*
TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
.mat-button-toggle-appearance-standard{
	color:$primary;
	background:$blue-100;
	border:none;
	/*
TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
	.mat-button-toggle-label-content.mat-button-toggle-label-content{
		padding: 0 16px;
		line-height:rem-calc(34);
	}
	/*
TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
	&.mat-button-toggle-checked{
		background:linear-gradient(to right, $blue, $primary-gradient);
	}
}
