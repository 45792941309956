$blue: #00abed;
$blue-gradient: #00d5f0;

$blue-200:  #9ee1fb;
$blue-100: #ecf3fa;
$blue-150: #a5f7ea;
$blue-10: #f7fafd;
$lightblue: #dde7f1;

$grey: #90a1b2;
$grey-medium:#ced1d3;

$grey-300:#adb7c1;
$grey-200:#d4dee9;
$grey-150:#dbe0e5;
$grey-100: #ecf1f5;

$lightgrey: #f1f5f9;

$purple: #cb64e9;
$purple-300:#e18ef9;
$purple-100: #f8f1fa;


$dark: #333333;
$light: #ffffff;
$green: #4ccc00;
$green-100: #bfec92;
$orange: #eea352;
$orange-300:#f9c184;
$orange-100:#f4ede2;
$red: #f44436;
$red-100: #ffcecf;
$yellow:#dfc448;
