.ins-stats-value,
.ins-stats-value-comp__value {
	font-size: 20px;
	display: block;

	&--large {
		font-size: 28px;
	}
}

.ins-stats-value-comp {
	display: block;

	&--center {
		text-align: center;
	}

	&--large {
		font-size: 28px;
	}

	&--small {
		.ins-stats-value-comp__value {
			font-size: 16px;
		}
	}
}

.ins-stats-value-title,
.ins-stats-value-comp__title {
	font-size: 11px;
	display: block;
	line-height: 1;
	text-transform: uppercase;
	margin-top: 2px;
}

.ins-stats-value-positive {
	color: $green;
}

.ins-stats-value-negative {
	color: $red;
}

// Game stats
.ins-game-stats {

	&__csv {
		display: flex;
		justify-content: center;
		margin-top: -24px;
		margin-bottom: 16px;

		button {
			border: 0;
			background: none;
			appearance: none;
			color: $primary;
			text-transform: uppercase;
			text-decoration: underline;
		}
	}

	&__top-row-column-container {
		position: relative;
		display: flex;

		.ins-card {
			min-height: 0;
			width: 100%;
		}
	}

	&__main-card {
		padding-left: 24px;
		padding-right: 24px;
	}

	&__main-stats-top {
		height: 135px;
	}

	&__result-stats-top {
		.ins-rating-stats__average {
			color: $body-color;
		}
	}

	&__bar {
		display: flex;
		align-items: center;
	}

	&__bar-item {
		margin-right: 40px;

		.ins-stats-value {
			color: $primary;
		}
	}

	&__more-container {
		display: flex;
		justify-content: center;
		padding: 4px 0;
		margin-top: -16px;

		@include media-breakpoint-up(lg){
			display: none;
		}

	}
}


// Game Channel Stats
.ins-game-channel-stats {
	display: block;
	position: relative;

	&__top {
		padding-top: 24px;
		padding-bottom: 24px;
	}

	&__top-header {
		display: flex;
		align-items: flex-end;
		padding: 0 24px;
		justify-content: space-between;
		margin-bottom: 24px;

		.mat-icon {
			width: 40px;
			height: 40px;
			font-size: 40px;
			display: block;
		}
	}

	&__top-right {
		text-align: right;
	}

	&__title {
		display: block;
		text-transform: uppercase;
		margin-top: 8px;
		font-size: 12px;
	}

	&__bottom {
		padding-top: 24px;
		padding-bottom: 24px;
		text-align: center;
	}
}


// Rating stats
.ins-rating-stats {
	display: block;

	&__top {
		padding: 16px 0;
		text-align: center;
	}

	&__average {
		font-size: 50px;
		line-height: 1.1;
		color: $primary;
	}

	&__descriptor {
		text-transform: uppercase;
	}

	&__team-name {
		text-transform: uppercase;
		font-size: 18px;
	}

	&__dials {
		padding-top: 24px;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}

	&__dials-high-scoring {
		padding-top: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__high-scoring-dial-title {
		text-transform: uppercase;
		text-align: center;
		flex: 0 0 130px;
		padding: 0 24px;
		font-size: 12px;
		font-weight: $font-weight-bold;
	}
}

// Detail game stats
.ins-game-stats-detail {
	display: block;
	margin-top: -16px;
	margin-bottom: 16px;
}

.ins-detail-game-stats-table {
	display: block;
	background: #f0f4f9;
	padding: 16px 24px;
	border: 1px solid $grey-200;
	border-top: 0;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	height: 100%;

	&__top-headline {
		text-align: center;
		display: block;
		text-transform: uppercase;
		font-size: 12px;
	}

	&__main-headline {
		text-align: center;
		text-transform: uppercase;
		color: $primary;
		font-size: 20px;
		margin-bottom: 12px;
		font-weight: normal;
	}

	&__name {
		width: 100%;
		min-width: 0;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	table {
		display: block;
		width: 100%;
		border-spacing: 0;
		border-collapse: collapse;

		tbody {
			display: block;
		}

		tr {
			display: flex;
			width: 100%;
			border-bottom: 1px solid #e3ebf4;
			padding: 0 24px;

			&:first-child {
				border-top: 1px solid #e3ebf4;
			}

			td {
				display: flex;
				padding: 0;
				align-items: center;
				text-transform: uppercase;
				font-size: 11px;

				&:last-child {
					text-align: right;
					justify-content: flex-end;
				}
			}

			&.ins-detail-game-stats-table-row-highlighted {
				background: #e1f0f7;
				font-weight: $font-weight-bold;
			}
		}
	}

	tr:nth-child(even) {
		background: #f6f9fb;
	}

	@include media-breakpoint-up(lg){
		display: none;
	}

}

.ins-stats-item-header {
	margin-bottom: 16px;
	display: block;
	//font-size: $font-size-medium;
	color: $dark;
}

.ins-stats-item {

	width: 100%;
	display: flex !important;
	flex-direction: column;
	flex: 0 0 23.5%;

	&:last-child {
		margin-right: 0;
	}

	.ins-dial {
		margin: 0 auto;
	}

	.ins-stats-item-bottom {
		margin-top: auto;
	}
}

.ins-stats-item-center-notice {
	margin: auto 0 !important;
	display: block;
	text-align: center;
	//font-size: $font-size-small;
	color: $dark;
}

.ins-stats-small {
	margin-bottom: 0;
	display: block;
	margin-top: 16px;
	//font-size: $font-size-small;
	color: $dark;
}

.ins-stats-large-number {
	font-size: 32px;
	margin: 8px 0;
}
