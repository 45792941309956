
.ins-column-container {
	display: flex;
	flex-wrap: wrap;
	margin-left: -1%;
	margin-right: -1%;

	.ins-column-full {
		flex: 0 0 98%;
		width: 100%;
		margin-left: 1%;
		margin-right: 1%;
	}

	.ins-column-half {
		flex: 0 0 48%;
		margin-left: 1%;
		margin-right: 1%;
		min-width: 0;
		position: relative;
	}

	.ins-column-third {
		display: block;
		flex: 0 0 31.3333%;
		margin-left: 1%;
		margin-right: 1%;
		min-width: 0;
		position: relative;
	}

	@include media-breakpoint-down(md){

		.ins-column-third,
		.ins-column-full,
		.ins-column-half--full-width-small {
			flex: 0 0 100%;
			margin-left: 0;
			margin-right: 0;
		}
	}
}
.ins-card {
	background: white;
	padding: 24px 20px;
	border-radius: 3px;
	border: 1px solid $grey-200;
	margin-bottom: 16px;

	&--no-padding {
		padding: 0;
	}

	&--margin-small {
		margin-bottom: 4px;
	}

	&--light {
		background: rgba(white, .5);
	}

	&--centered {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
}

.ins-dashboard {
	&__dual-card {
		display: flex;
		justify-content: space-between;
		flex-grow: 1;

		.ins-card {
			width: 49%;
			flex-grow: 0;
		}
	}

	&__bottom-card {
		padding: 16px;
		flex-grow: 1;
	}

	&__timeframe {
		display: flex;
		align-items: center;

		span {
			margin-right: 12px;
		}
	}

	&__dual-value {
		display: flex;
		justify-content: space-evenly;
	}

	&__bottom-value-container {
		margin-top: -8px;
	}

	&-stats__column-container .ins-column-third {
		display: flex;
		flex-direction: column;
	}

	.ins-page-header__right {
		display: flex;
		align-items: center;

		button {
			margin-right: 16px;
		}
	}
}

.ins-channel-stats {
	display: block;

	&__top {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__bottom {
		padding: 16px 0;
	}

	&__graph-title {
		text-align: center;
		text-transform: uppercase;
		font-size: 14px;
		margin-top: 0;
		margin-bottom: -8px;
		height: 24px;
	}

	&__center {
		display: flex;
		justify-content: center;
		margin-top: -24px;
		flex-shrink: 0;

		&--hidden {
			visibility: hidden;
		}
	}

	&__title {
		text-transform: uppercase;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		line-height: 1;
		font-size: 12px;

		.mat-icon {
			width: 48px;
			height: 48px;
			font-size: 48px;
			display: block;
			margin-bottom: 12px;
		}
	}

	&__delta {
		font-size: 44px;
		line-height: 1;
		letter-spacing: 0;
		flex: 0 0 30%;
		min-width: 0;
		color: $green;
		font-weight: 300;
		position: relative;
		top: 5px;

		@media(max-width: 1100px) {
			font-size: 32px;
			margin-left: -8px;
		}
	}

	&__delta-percent {
		font-size: 16px;
	}

	&__graph {
		margin: 8px 0;
		height: 120px;
	}

	&__top-value {
		flex: 0 0 30%;
		flex-grow: 1;
		min-width: 0;

		&:last-child {
			text-align: right;
		}
	}

	&--negative {
		.ins-channel-stats__delta {
			color: $red;
		}
	}

	.ins-graph-dates {
		padding: 0 24px;
		margin-bottom: 32px;
	}
}

.ins-value-change-graph {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;

	&--reverse {
		flex-direction: column-reverse;
	}

	&__graph {
		position: relative;
		width: 100%;
		height: 100px;

		&--small {
			width: 150px;
			height: 70px;
		}
	}
}

.ins-graph-dates {
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: -20px;

	&__date {
		text-align: center;
		text-transform: uppercase;

		span {
			display: block;
			font-size: 9px;
		}

		.ins-graph-dates__day {
			font-size: 12px;
		}
	}
}




.ins-dashboard-broadcasts {

	&__column-name {
		flex: 0 0 55%;

		&.mat-mdc-cell {
			padding-right: 24px;

			button {
				margin-left: auto;
			}
		}
	}

	&__column-date,
	&__column-interactions,
	&__column-users-sent {
		flex: 0 0 15%;
		justify-content: flex-end;
		padding-right: 16px;
	}

	&__column-interactions,
	&__column-users-sent {
		&.mat-mdc-cell {
			font-size: 18px;
		}
	}

	.mat-mdc-row {
		padding-top: 8px;
		padding-bottom: 8px;
	}

	&__time {
		font-size: 12px;
		display: block;
		text-align: right;
	}

	.ins-badge {
		border-radius: 3px;
		padding: 6px 8px;
		font-size: 11px;
		text-transform: uppercase;
		line-height: 1;
		margin-right: 8px;

		&--purple {
			color: #333333;
			background: #eed7fa;
		}
	}
}

.ins-cohort-table {
	padding: 0;

	&__title {
		margin-bottom: 8px;
		font-size: 11px;
		display: flex;
		text-transform: uppercase;
		align-items: center;

		.mat-icon {
			margin-right: 8px;
			font-size: 20px;
			width: 20px;
			height: 20px;
		}
	}

	.mat-mdc-table {
		border: 0;
	}

	.mat-mdc-cell,
	.mat-mdc-header-cell {
		text-align: center;
		font-size: 11px;

		@media(max-width: 1000px) {
			font-size: 9px;
		}
	}

	.mat-mdc-header-cell {
		justify-content: center;
		font-weight: normal;

		&:first-of-type {
			padding-left: 0;
			flex: 0 0 100px;
		}

		&:last-of-type {
			padding-right: 0;
		}
	}

	.mat-mdc-header-row {
		padding-left: 0;
		padding-right: 0;
		min-height: 24px;
		border-bottom: 0;
	}

	.mat-mdc-row {
		align-items: normal;
		padding-left: 0;
		padding-right: 0;
		min-height: 24px;
		border-bottom-color: white;
	}

	.mat-mdc-cell {
		display: flex;
		justify-content: center;
		align-items: normal;
		border-right: 1px solid white;

		&:first-of-type {
			padding-left: 0;
			text-transform: uppercase;
			flex: 0 0 100px;
			justify-content: flex-start;

			.ins-cohort-table__cell-inner {
				justify-content: flex-start;
			}

			@media(max-width: 1000px) {
				flex: 0 0 80px;
			}
		}

		&:last-of-type {
			padding-right: 0;
		}

		&.ins-cohort-table__cell-first-value {
			.ins-cohort-table__cell-inner {
				border-top-left-radius: 99px;
				border-bottom-left-radius: 99px;
			}
		}

		&.ins-cohort-table__cell-last-value {
			.ins-cohort-table__cell-inner {
				border-top-right-radius: 99px;
				border-bottom-right-radius: 99px;
			}
		}
	}

	&__cell-inner {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		&--gray {
			background-color: #eee;

			span {
				display: none;
			}
		}
	}
}

